import GeoTargetTab from 'aac-components/components/GeoTargetTab';
import {useRouter} from 'next/router';
import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {BREAKPOINT} from '../../lib/styles';
import {debounce} from 'aac-components/utils/throttle';
import facilityInfo from '../../lib/facility-info.json';
import TopReasonsLink from '../TopReasonsLink';
import AppContext from '../AppContext';
import {geoTargetFacilityData} from './FacilityData';

const GeoTargetTabWrapper = ({content_segment, callTrackingNumber, geolocation}) => {
    const {asPath} = useRouter();
    const {pageProps: {data: {template = ''} = {}} = {}} = useContext(AppContext);

    const showGeoTab =
        content_segment !== 'conversion-pages' &&
        !asPath.includes('/verify-insurance') &&
        template !== 'compliance.php';

    const [scrollDepth, setScrollDepth] = useState(0);
    const [pageHasPills, setPageHasPills] = useState(true);

    useEffect(() => {
        const onScroll = () => {
            setScrollDepth(window && window.pageYOffset);
        };
        window.addEventListener('scroll', debounce(onScroll, 500));
        return () => window.removeEventListener('scroll', onScroll);
    }, [asPath]);

    useEffect(() => {
        const pagePills = document && document?.getElementById('page-pills');
        setPageHasPills(!!pagePills);
    }, [asPath]);

    const siteKey = geolocation?.facility;

    const facilitySlug = Object.keys(facilityInfo).reduce((acc, curr) => {
        if (facilityInfo?.[curr]?.siteKey == siteKey) {
            acc.push(curr);
        }
        return acc;
    }, [])?.[0];

    const facility = facilityInfo?.[facilitySlug];

    if (!showGeoTab || pageHasPills) return null;

    return (
        <GeoTargetTabStyles scrollDepth={scrollDepth}>
            <GeoTargetTab
                callTrackingNumber={callTrackingNumber}
                geolocation={geolocation}
                hasCloseButton={true}
                customGeoTargetFacilityData={geoTargetFacilityData}>
                <TopReasonsLink
                    slug={facility?.topReasonsSlug || facilitySlug}
                    name={facility?.short_name || facility?.name}
                />
            </GeoTargetTab>
        </GeoTargetTabStyles>
    );
};
export default GeoTargetTabWrapper;

// do this on mobile to avoid conflict with sticky footer and next-steps nav
const GeoTargetTabStyles = styled.div`
    #geotarget-tab {
        top: ${(props) => (props?.scrollDepth > 100 ? 25 : 35)}%;
        transition: all 0.25s;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        #geotarget-tab {
            top: 35%;
        }
    }
`;
