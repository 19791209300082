export const geoTargetFacilityData = [
    {
        // 1950 Tower Hill Rd, North Kingstown, RI 02852
        siteKey: 'ARI',
        chartFacName: 'AdCare Treatment Centers',
        latitude: 41.55646,
        longitude: -71.46498,
        radius: 75,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-adc.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/adcare-rhode-island">
                    AdCare Treatment Centers
                </a>{' '}
                are located in MA & RI. With 2 large detox and residential campuses and
                multiple outpatient locations, we can help.
            </>
        ),
    },
    {
        // 107 Lincoln St, Worcester, MA 01605
        siteKey: 'AHW',
        chartFacName: 'AdCare Treatment Centers',
        latitude: 42.27641,
        longitude: -71.79469,
        radius: 75,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-adc.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/adcare-hospital">
                    AdCare Treatment Centers
                </a>{' '}
                are located in MA & RI. With 2 large detox and residential campuses and
                multiple outpatient locations, we can help.
            </>
        ),
    },
    {
        siteKey: 'DSH',
        chartFacName: 'Desert Hope Treatment Center',
        latitude: 36.12127,
        longitude: -115.11721,
        radius: 75,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-dh.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/desert-hope">
                    Desert Hope Treatment Center
                </a>{' '}
                is a beautiful oasis with modern charm located in Las Vegas, Nevada. We
                provide all levels of care including detox, residential, outpatient and
                sober living. <b>Get admitted same-day for those who qualify!</b>
            </>
        ),
    },
    {
        siteKey: 'GRN',
        chartFacName: 'Greenhouse Treatment Center',
        latitude: 32.76465,
        longitude: -97.05919,
        radius: 75,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-gh.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/greenhouse">
                    Greenhouse Treatment Center
                </a>{' '}
                is a spa-like retreat located in the Dallas-Fort Worth area. We provide
                all levels of care including detox, residential, outpatient and sober
                living.
            </>
        ),
    },
    {
        siteKey: 'LAG',
        chartFacName: 'Laguna Treatment Center',
        latitude: 33.57132,
        longitude: -117.70116,
        radius: 75,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-la.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/laguna-treatment-center">
                    Laguna Treatment Center
                </a>{' '}
                is located in Orange Country, CA. We offer detox and residential addiction
                treatment services.
            </>
        ),
    },
    {
        siteKey: 'XFO',
        chartFacName: 'Oxford Treatment Center',
        latitude: 34.48761,
        longitude: -89.28126,
        radius: 120,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-ox.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/oxford">
                    Oxford Treatment Center
                </a>{' '}
                is nestled in North Mississipi's scenic National Forest. We provide all
                levels of care including detox, residential, outpatient and sober living.
            </>
        ),
    },
    {
        siteKey: 'REF',
        chartFacName: 'Recovery First Treatment Center',
        latitude: 26.04388,
        longitude: -80.23102,
        radius: 75,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-rf.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/recovery-first-hollywood">
                    Recovery First Treatment Center
                </a>{' '}
                is located in Southern Florida. Join our close-nit community to start your
                recovery.
            </>
        ),
    },
    {
        siteKey: 'ROA',
        chartFacName: 'River Oaks Treatment Center',
        latitude: 27.85545,
        longitude: -82.29685,
        radius: 75,
        img: 'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/geotarget-tab-ro.jpg',
        description: (
            <>
                <a href="https://americanaddictioncenters.org/treatment-centers/river-oaks">
                    River Oaks Treatment Center
                </a>{' '}
                is located in the Tampa area. We provide all levels of care including
                detox, residential, and outpatient.
            </>
        ),
    },
];
